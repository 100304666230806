<template>
  <div>
    <div class="row justify-content-center">
      <div class="form-group col-4">
        <label for>Password</label>
        <fg-input
          type="password"
          name="password"
          placeholder="Password"
          v-model="model.password"
          v-validate="modelValidations.password"
          :error="getError('password')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Blood Group</label>
        <el-select
          name="blood"
          class="select-default-special"
          placeholder="Pick a Blood Group"
          v-model="model.blood_group"
        >
          <el-option
            v-for="option in blood_groups"
            class="select-default-special"
            :value="option"
            :label="option"
            :key="option"
          ></el-option>
        </el-select>
      </div>

      <div class="form-group col-4">
        <label for>Ref Name</label>
        <fg-input
          type="text"
          name="ref_name"
          placeholder="Ref Name"
          v-model="model.ref_name"
          v-validate="modelValidations.ref_name"
          :error="getError('ref_name')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Ref Contact</label>
        <fg-input
          type="text"
          name="ref_contact"
          placeholder="Ref Contact"
          v-model="model.ref_contact"
          v-validate="modelValidations.ref_contact"
          :error="getError('ref_contact')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Date Joined</label>
        <fg-input
          name="doj"
          placeholder="doj"
          v-model="model.doj"
          v-validate="modelValidations.doj"
          :error="getError('doj')"
        >
          <el-date-picker
            name="doj"
            v-model="model.doj"
            type="date"
            placeholder="Pick a Date"
            v-validate="modelValidations.doj"
            :error="getError('doj')"
          ></el-date-picker>
        </fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Languages</label>
        <el-select
          v-model="model.languages"
          multiple
          filterable
          allow-create
          default-first-option
          placeholder="Choose tags for your article"
        >
          <el-option
            v-for="item in local.languages"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <div class="form-group col-4">
        <label for>Designation</label>
        <fg-input
          name="designation"
          placeholder="designation"
          v-model="model.role"
          v-validate="modelValidations.designation"
          :error="getError('designation')"
        >
          <el-select
            name="designation"
            class="select-default-special"
            placeholder="Pick a Designation"
            v-model="model.role"
          >
            <el-option
              v-for="option in roles"
              class="select-default-special"
              :value="option.id"
              :label="option.name"
              :key="option.id"
            ></el-option>
          </el-select>
        </fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Bio</label>
        <fg-input
          type="text"
          name="bio"
          placeholder="Bio"
          v-model="model.bio"
          v-validate="modelValidations.bio"
          :error="getError('bio')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Education</label>
        <fg-input
          type="text"
          name="education_level"
          placeholder="Education"
          v-model="model.education_level"
          v-validate="modelValidations.education_level"
          :error="getError('education_level')"
        ></fg-input>
      </div>
    </div>
  </div>
</template>
<script>
import { Upload, DatePicker, Select, Option } from "element-ui";
import { mapGetters } from "vuex";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Upload.name]: Upload,
    [Select.name]: Select,
    [Option.name]: Option
  },
  computed: {
    ...mapGetters({
      blood_groups: "getBloodGroups",
      roles: "getRoles"
    })
  },
  mounted(){
    this.$store.dispatch('fetchRolesAll');
  },
  data() {
    return {
      local: {
        languages: [
          { value: "English", label: "English" },
          { value: "Hindi", label: "Hindi" },
          { value: "Kannada", label: "Kannada" },
          { value: "Tamil", label: "Tamil" },
          { value: "Malayalam", label: "Malayalam" }
        ],
        designations: [
          { value: "Accountant", label: "Accountant" },
          { value: "Front Desk", label: "Front Desk" },
          { value: "Prescription Writer", label: "Prescription Writer" }
        ]
      },
      model: {
        blood_group: "",
        ref_name: "",
        ref_contact: "",
        doj: "",
        designation: "",
        bio: "",
        education_level: "",
        password: "secret",
        role: "",
        languages: ""
      },
      modelValidations: {
        blood_group: {
          required: true
        },
        ref_name: {
          required: true
        },
        ref_contact: {
          required: true
        },
        doj: {
          required: true
        },
        designation: {
          required: true
        },
        education_level: {
          required: true
        },
        password: {
          required: true,
          min: 6
        }
      }
    };
  },
  methods: {
    handlePreview(file) {
      this.model.imageUrl = URL.createObjectURL(file.raw);
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      return this.$validator.validateAll().then(res => {
        this.$emit("on-validated", res, this.model);
        return res;
      });
    }
  }
};
</script>
<style lang="scss">
.card-wizard .picture-container .picture {
  input[type="file"] {
    display: none;
  }
}
</style>

<template>
  <div>
    <h5 class="info-text">Let's start with the basic information</h5>
    <div class="row">
      <div class="form-group col-4" v-if="false">
        <label>Employee Id</label>
        <fg-input name="employee_id" placeholder="Employee Id" v-model="model.employee_id" readonly></fg-input>
      </div>

      <div class="form-group col-4" v-if="false">
        <label>User Id</label>
        <fg-input name="user_id" placeholder="User Id" v-model="model.user_id" readonly></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Name</label>
        <fg-input
          type="text"
          name="name"
          placeholder="Name"
          v-model="model.name"
          v-validate="modelValidations.name"
          :error="getError('name')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Phone</label>
        <fg-input
          type="number"
          name="phone"
          placeholder="Phone"
          v-model="model.phone"
          v-validate="modelValidations.phone"
          :error="getError('phone')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Alternative Phone</label>
        <fg-input
          type="number"
          name="alt_phone"
          placeholder="Alternative Phone"
          v-model="model.alt_phone"
          v-validate="modelValidations.alt_phone"
          :error="getError('alt_phone')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Email</label>
        <fg-input
          type="email"
          name="email"
          placeholder="Email"
          v-model="model.email"
          v-validate="modelValidations.email"
          :error="getError('email')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>DOB</label>
        <fg-input
          name="dob"
          placeholder="dob"
          v-model="model.dob"
          v-validate="modelValidations.dob"
          :error="getError('dob')"
        >
        <el-date-picker
          name="dob"
          v-model="model.dob"
          type="date"
          placeholder="Pick DOB"
          v-validate="modelValidations.dob"
        ></el-date-picker>
        </fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Father's Name</label>
        <fg-input
          type="text"
          name="father_name"
          placeholder="Father's Name"
          v-model="model.father_name"
          v-validate="modelValidations.father_name"
          :error="getError('father_name')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Mother's Name</label>
        <fg-input
          type="text"
          name="mother_name"
          placeholder="Mother's Name"
          v-model="model.mother_name"
          v-validate="modelValidations.mother_name"
          :error="getError('father_name')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Spouse's Name</label>
        <fg-input
          type="text"
          name="spouse_name"
          placeholder="Spouse's Name"
          v-model="model.spouse_name"
          v-validate="modelValidations.spouse_name"
          :error="getError('father_name')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Maritial Status</label>
        <fg-input
          type="text"
          name="maritial_status"
          v-model="model.maritial_status"
          v-validate="modelValidations.maritial_status"
          :error="getError('maritial_status')"
        >
        <el-select
          name="maritial_status"
          class="select-default-special"
          placeholder="Maritial Status"
          v-model="model.maritial_status"
        >
          <el-option
            v-for="option in maritial_statuses"
            class="select-success"
            :value="option"
            :label="option"
            :key="option"
          ></el-option>
        </el-select>
        </fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Gender</label>
        <fg-input
          name="gender"
          v-model="model.gender"
          v-validate="modelValidations.gender"
          :error="getError('gender')"
        >
        <el-select
          name="gender"
          class="select-default-special"
          placeholder="Select Gender"
          v-model="model.gender"
        >
          <el-option
            v-for="option in genders"
            class="select-success"
            :value="option"
            :label="option"
            :key="option"
          ></el-option>
        </el-select>
        </fg-input>
      </div>

      <!-- <div class="form-group col-4 offset-4">
        <label for>Religion</label>
        <el-select
          name="religion"
          class="select-default-special"
          placeholder="Religion"
          v-model="model.religion"
        >
          <el-option
            v-for="option in religions"
            class="select-success"
            :value="option"
            :label="option"
            :key="option"
          ></el-option>
        </el-select>
      </div> -->

    </div>
  </div>
</template>
<script>
import { Upload, DatePicker, Select, Option } from "element-ui";
import { mapGetters } from "vuex";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Upload.name]: Upload,
    [Select.name]: Select,
    [Option.name]: Option
  },
  computed: {
    ...mapGetters({
      genders: "getGenders",
      maritial_statuses: "getMaritalStatuses",
      religions: "getReligions",
    }),
  },
  data() {
    return {
      model: {
        employee_id: "",
        user_id: "",
        name: "",
        phone: "",
        alt_phone: "",
        email: "",
        dob: "",
        gender: "",
        father_name: "",
        mother_name: "",
        spouse_name: "",
        maritial_status: "",
        religion: ""
      },
      modelValidations: {
        name: {
          required: true
        },
        dob: {
          required: true
        },
        gender: {
          required: true
        },
        phone: {
          required: true,
          max: 10,
          min: 10
        },
        alt_phone: {
          required: true,
          max: 10,
          min: 10
        },
        email: {
          required: true,
          email: true
        },
        father_name: {
          required: true
        },
        mother_name: {
          required: true
        }
      }
    };
  },
  methods: {
    handlePreview(file) {
      this.model.imageUrl = URL.createObjectURL(file.raw);
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      return this.$validator.validateAll().then(res => {
        this.$emit("on-validated", res, this.model);
        return res;
      });
    }
  }
};
</script>
<style lang="scss">
.card-wizard .picture-container .picture {
  input[type="file"] {
    display: none;
  }
}

.el-select .el-input:hover .el-input__icon, 
.el-select .el-input:hover input{
  color: #66615b;
}

.form-group .el-select .el-input__inner[readonly]{
  color: #66615b;
  background-color: #fff!important;
}

.el-select .el-input__inner:focus,
.el-select .el-input.is-focus .el-input__inner{
  border: 1px solid #9a9a9a;
}

</style>
